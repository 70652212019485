import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { UserRoles } from '../core.types';
import { AuthenticationService } from './authentication.service';
import { Logger } from './logger.service';

@Injectable()
export class AuthGuardService {
  private readonly log = new Logger('AuthGuardService');
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const token = await this.authService
      .getAccessToken$()
      .pipe(take(1))
      .toPromise();
    if (token) {
      if (route.data && route.data.roles && route.data.roles.length > 0) {
        const userRoles = this.authService.getRolesFromAccessToken(token);
        const matchingRoles = route.data.roles.filter((role1: UserRoles) =>
          userRoles.some((role2) => role1 === role2)
        );
        if (matchingRoles.length < 1) {
          this.log.debug(`user roles not matching ${userRoles.join(',')}`);
          return false;
        }
      }
      this.log.debug(`allow`);
      return true;
    } else {
      this.log.debug(`deny`);
      this.router.navigate(['/auth']);
      return false;
    }
  }
}
