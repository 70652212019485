import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { Logger } from './logger.service';

@Injectable()
export class UnAuthGuardService {
  private readonly log = new Logger('UnAuthGuardService');
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const token = await this.authService
      .getAccessToken$()
      .pipe(take(1))
      .toPromise();
    if (token) {
      this.log.debug('deny');
      return this.router.parseUrl('/');
    } else {
      this.log.debug('allow');
      return true;
    }
  }
}
